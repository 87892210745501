<template>
  <div v-if="noResult">
    <h5>No Videos Found</h5>
  </div>
</template>


<script>
export default {
  name: "NoSearchResult",
  props: {
    noResult: Boolean
  }
};
</script>
