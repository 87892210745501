<template>
  <div>
    <nav class="o2-navbar">
      <div>
        <span class="o-menu-logo">
          <router-link to="/">
            <img src="/opofa-logo-128.png" alt="opofa" />
            <span class="d-none d-sm-none d-md-inline">{{ title }}</span>
            <!--<font-awesome-icon :icon="['fab', 'youtube']" />-->
          </router-link>
        </span>
        <span v-show="isSearching===true" class="o-menu-search">
          <input
            ref="filter"
            name="filter"
            v-model="filter"
            placeholder="Type to filter..."
            @blur="hideSearchInput"
          />
        </span>

        <ul v-show="isSearching===false" class="o-menu-icons">
          <li v-show="page === 'home' || page === 'videos'" title="Search" @click="showSearchInput">
            <font-awesome-icon :icon="['fas', 'search']" />
          </li>
          <li title="Videos">
            <router-link to="/videos">
              <font-awesome-icon :icon="['fas', 'video']" />
            </router-link>
          </li>
          <li title="PlayLists">
            <router-link to="/playlists">
              <font-awesome-icon :icon="['fas', 'list']" />
            </router-link>
          </li>
          <li title="Channel">
            <router-link to="/channel">
              <font-awesome-icon :icon="['fas', 'user']" />
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  components: {},
  data() {
    return {
      isSearching: false,
      filter: ""
    };
  },
  computed: {
    ...mapGetters(["title", "videos"]),
    page() {
      return this.$route.name;
    }
  },
  methods: {
    ...mapActions(["setFilteredVideos"]),
    showSearchInput() {
      this.isSearching = true;

      this.$nextTick(() => {
        this.$refs.filter.focus();
      });
    },
    hideSearchInput() {
      this.isSearching = false;
    }
  },
  watch: {
    filter: function(newFilter) {
      const videos = this.videos.filter(
        video =>
          video.snippet.title.toLowerCase().indexOf(newFilter.toLowerCase()) !==
          -1
      );

      this.setFilteredVideos(videos);
    }
  }
};
</script>

<style scoped>
nav.o2-navbar {
  position: fixed;
  display: -ms-flexbox;
  display: -webkit-box;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 0;
  background-color: black;
  color: silver;
  z-index: 1000;
}

nav.o2-navbar div {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
}

nav.o2-navbar a {
  color: silver;
  text-decoration: none;
}

nav.o2-navbar a:hover {
  color: white;
}

nav.o2-navbar div input {
  width: 100%;
  padding: 0.2rem;
  border: 0.1rem solid gray;
  border-radius: 0.5rem;
  background-color: black;
  color: silver;
  outline: none;
  font-size: 1rem;
}

nav.o2-navbar div input:hover,
nav.o2-navbar div input:focus {
  border-color: white;
}

nav.o2-navbar img {
  height: 3rem;
}

nav.o2-navbar div span.o-menu-logo {
  float: left;
  height: 2rem;
}

nav.o2-navbar div span.o-menu-search {
  display: block;
  float: right;
  width: 80%;
  height: 2rem;
}

nav.o2-navbar div ul.o-menu-icons {
  float: right;
  margin: 0;
  padding: 0;
  height: 2rem;
  list-style: none;
}

nav.o2-navbar div ul.o-menu-icons li {
  padding: 0 0.5rem;
  cursor: pointer;
  display: block;
  float: left;
}

nav.o2-navbar div ul.o-menu-icons li:hover {
  color: white;
}
</style>
