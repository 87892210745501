<template>
  <div class="o2-container">
    <PageTitle text="Home" />
    <Loader :isLoading="isLoading" />
    <NoSearchResult :noResult="noResult" />
    <Videos :videos="filteredVideos" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Loader from "@/components/layout/Loader";
import PageTitle from "@/components/layout/PageTitle";
import NoSearchResult from "@/components/layout/NoSearchResult";

import Videos from "./Videos";

export default {
  name: "HomeContainer",
  props: {},
  components: { Loader, PageTitle, NoSearchResult, Videos },
  computed: {
    ...mapGetters(["isLoading", "videos", "filteredVideos"]),
    noResult() {
      return !this.isLoading && this.filteredVideos.length == 0;
    }
  },
  methods: {
    ...mapActions(["setVideos"])
  },
  mounted() {
    if (this.videos.length === 0) {
      this.setVideos();
    }
  }
};
</script>

<style scoped>
</style>
