<template>
  <div id="app">
    <Header />
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <Footer />
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
html,
body {
  margin: 0;
  background-color: #000;
  color: #fff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

.o2-container {
  position: absolute;
  width: 100%;
  margin: 0;
  margin-top: 4rem;
  margin-bottom: 3rem;
  padding: 2rem;
  padding-top: 1rem;
  width: 100%;
  border-top: 0.1rem solid #222;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
