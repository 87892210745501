import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    title: "OTube",
    isLoading: false,
    channel: {},
    playlists: [],
    videos: [],
    filteredVideos: [],
    player: {
      id: "",
      type: "",
      src: ""
    }
  },
  getters: {
    title: state => {
      return state.title;
    },
    isLoading: state => {
      return state.isLoading;
    },
    channel: state => {
      return state.channel;
    },
    playlists: state => {
      return state.playlists;
    },
    videos: state => {
      return state.videos;
    },
    filteredVideos: state => {
      return state.filteredVideos;
    },
    player: state => {
      return state.player;
    }
  },
  actions: {
    setChannel({ commit }) {
      commit("SET_ISLOADING", true);

      axios
        .get("https://api.opofa.com/youtube/channel", {
          params: {},
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const channel = response.data.items.filter(
            item => item.kind === "youtube#channel"
          );

          commit("SET_CHANNEL", channel[0]);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => commit("SET_ISLOADING", false));
    },
    setPlayLists({ commit }) {
      commit("SET_ISLOADING", true);

      axios
        .get("https://api.opofa.com/youtube/playlists", {
          params: {},
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const playlists = response.data.items.filter(
            item => item.kind === "youtube#playlist"
          );

          commit("SET_PLAYLISTS", playlists);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => commit("SET_ISLOADING", false));
    },
    setVideos({ commit }) {
      commit("SET_ISLOADING", true);

      axios
        .get("https://api.opofa.com/youtube/videos", {
          params: {},
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const videos = response.data.items.filter(
            item => item.id.kind === "youtube#video"
          );

          commit("SET_VIDEOS", videos);
          commit("SET_FILTERED_VIDEOS", videos);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => commit("SET_ISLOADING", false));
    },
    setFilteredVideos({ commit }, videos) {
      commit("SET_FILTERED_VIDEOS", videos);
    },
    setPlayer({ commit }, playerInput) {
      const player = { ...playerInput };

      if (player.type === "video") {
        player.src = `https://www.youtube.com/embed/${player.id}`;
      }

      if (player.type === "playlist") {
        player.src = `https://www.youtube.com/embed/videoseries?list=${player.id}`;
      }

      commit("SET_PLAYER", player);
    }
  },
  mutations: {
    SET_ISLOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_CHANNEL(state, channel) {
      state.channel = channel;
    },
    SET_PLAYLISTS(state, playlists) {
      state.playlists = playlists;
    },
    SET_VIDEOS(state, videos) {
      state.videos = videos;
    },
    SET_FILTERED_VIDEOS(state, filteredVideos) {
      state.filteredVideos = filteredVideos;
    },
    SET_PLAYER(state, player) {
      state.player = player;
    }
  }
});

export default store;
