<template>
  <div :class="visibility">
    <div class="o2-centered">
      <h6>
        Video Info
        <button @click="close">x</button>
      </h6>
      <dl>
        <dt>Title</dt>
        <dd>{{ video.snippet ? video.snippet.title : ""}}</dd>
        <dt>Description</dt>
        <dd>{{ video.snippet ? video.snippet.description : "" }}</dd>
        <dt>Published At</dt>
        <dd>{{ video.snippet ? video.snippet.publishedAt : "" }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoInfo",
  props: {
    show: Boolean,
    video: Object
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    visibility() {
      return this.show ? "o2-show" : "o2-hide";
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.5);
  z-index: 2000;
}

div.o2-show {
  display: block;
}

div.o2-hide {
  display: none;
}

div.o2-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-style: solid;
  border-color: white;
  border-radius: 1rem;
  background: rgb(0, 0, 0, 0.9);
  color: white;
  overflow: hidden;
}

div.o2-centered button {
  float: right;
  border: 0;
  background: transparent;
  color: silver;
  font-weight: bold;
}

div.o2-centered button:hover {
  color: white;
}

h6 {
  font-size: 1.2rem;
}

dl {
  text-align: left;
  font-size: 0.8rem;
}
</style>
