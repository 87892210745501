<template>
  <div class="cell">
    <router-link :to="`/player/video/${video.id.videoId}`">
      <div class="video-player" title="Open Player">▶</div>
    </router-link>
    <div class="video-info" title="Open Info Panel" @click="getInfo">&#9432;</div>
    <iframe
      v-bind:srcdoc="
        `<style>
        *{padding:0;margin:0;overflow:hidden}
        html,body{height:100%}
        img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}
        span{height:1.5em;text-align:center;font:32px/1 sans-serif;color:silver;text-shadow:0 0 0.2em black}
        body:hover span{color:white}
        </style>
        <a href=https://www.youtube.com/embed/${video.id.videoId}?autoplay=1&mute=1>
        <img src=https://img.youtube.com/vi/${video.id.videoId}/hqdefault.jpg alt='${video.snippet.title}'  title='${video.snippet.title}'>
        <span>▶</span>
        </a>`
      "
      class="o2-embed"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="video-title">{{ video.snippet.title }}</div>
  </div>
</template>

<script>
export default {
  name: "Video",
  props: {
    video: {}
  },
  methods: {
    getInfo() {
      this.$emit("getInfo", this.video);
    }
  }
};
</script>

<style scoped>
.cell {
  position: relative;
  padding-bottom: 56%;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
}

.cell .o2-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.cell div.video-player {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  user-select: none;
  font-weight: bold;
  cursor: pointer;
  border-bottom-right-radius: 0.5rem;
  transition: opacity 0.2s ease-out;
}

.cell div.video-player {
  color: silver;
  text-decoration: none;
}

.cell div.video-player:hover {
  color: white;
  text-decoration: none;
}

.cell:hover div.video-player {
  opacity: 1;
}

.cell div.video-info {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  user-select: none;

  font-weight: bold;
  cursor: pointer;
  border-bottom-left-radius: 0.5rem;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0;
}

.cell:hover div.video-info {
  opacity: 1;
}

.cell div.video-title {
  opacity: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  transition-property: opacity, padding, font-size;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  transition-delay: 0;
}

.cell:hover div.video-title {
  opacity: 0;
  padding: 0;
  font-size: 0;
}
</style>
