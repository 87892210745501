<template>
  <div>
    <div class="row">
      <div
        class="col-xl-3 col-lg-4 col-sm-6 col-xs-12 p-3"
        v-bind:key="video.id.videoId"
        v-for="video in videos"
      >
        <Video :video="video" @getInfo="showInfoPanel" />
      </div>
    </div>
    <VideoInfo :show="showInfo" :video="video" @close="hideInfoPanel" />
  </div>
</template>

<script>
import Video from "./Video";
import VideoInfo from "./VideoInfo";

export default {
  name: "Videos",
  props: {
    videos: {}
  },
  components: {
    Video,
    VideoInfo
  },
  data() {
    return {
      showInfo: false,
      video: {}
    };
  },
  methods: {
    showInfoPanel(video) {
      this.video = video;
      this.showInfo = true;
    },
    hideInfoPanel() {
      this.showInfo = false;
      this.video = {};
    }
  }
};
</script>
