<template>
  <div v-if="isLoading == true">
    <font-awesome-icon :icon="['fas', 'spinner']" spin />
  </div>
</template>


<script>
export default {
  name: "Loader",
  props: {
    isLoading: Boolean
  }
};
</script>
