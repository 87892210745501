<template>
  <nav>
    <a href="https://video.opofa.com" target="_blank">
      <small>{{ copyright }}</small>
    </a>
  </nav>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    copyright() {
      return `Copyright © ${this.currentYear} opofa.com. All rights reserved`;
    },
  },
};
</script>

<style scoped>
nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.2rem;
  background-color: #222;
  color: silver;
  border-top: 0.1rem solid silver;
  text-align: center;
}

nav a {
  width: 100%;
  margin: 0;
  padding: 0;
  color: silver;
  text-decoration: none;
}

nav a:hover {
  color: white;
}

nav a small {
  font-size: 0.8rem;
}
</style>
