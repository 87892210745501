import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faSearch,
  faUser,
  faVideo,
  faList,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

library.add(faSearch, faUser, faVideo, faYoutube, faList, faSpinner);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.directive("visible", (el, binding) => {
  el.style.visibility = binding.value ? "visible" : "hidden";
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount("#app");
