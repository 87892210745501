<template>
  <HomeContainer />
</template>

<script>
import HomeContainer from "@/components/pages/home/HomeContainer.vue";

export default {
  name: "HomePage",
  components: {
    HomeContainer
  }
};
</script>
