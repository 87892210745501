<template>
  <h3>{{text}}</h3>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    text: String
  }
};
</script>